import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hang Squat Snatch 4×4\\@80%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`5-Power Snatch (115/75)`}</p>
    <p>{`7-OHS`}</p>
    <p>{`9-T2B`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`30 Calorie Ski Erg`}</p>
    <p>{`30 Burpees`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      